export default {
    app: {
        name: 'T.PRO',
        search: 'Поиск',
    },

    user: {
        desk: {
            settings: 'Настройки',
            logout: 'Выйти',
            history: 'История',
        },
    },

    titles: {
        catalog: 'Общий журнал',
        snips: 'Строительные нормы и правила',
        modal: 'Уведомление от T.Pro',
        uploadScheme: 'Загрузить чертеж',
        add: 'Добавить',
        acceptance: 'Приёмка квартир',
        warranty: 'Гарантия',
    },

    services: {
        designing: 'Проектирование',
        estimates: 'Сметы',
        prescription: 'Предписания',
        acceptance: 'Приёмка квартир',
        warranty: 'Гарантия',
        cloud: 'T.Cloud',
        qlik: 'Qlik',
        adminPanel: 'Администрирование',
        projectManagement: 'Управление проектами',
    },

    routes: {
        services: 'Сервисы',
        offer: 'Оферта',
        price: 'Прайс',
        report: 'Отчеты',

        // Предписания
        prescriptionHome: 'Объекты',
        prescriptionRequirements: 'Строительные нормы и правила',
        prescriptionScheme: 'Загрузить чертеж',
        prescriptionList: 'Общий журнал',
        prescriptionView: 'Просмотр предписания',
        prescriptionCreate: 'Создание предписания',
        prescriptionAcceptance: 'Приёмка квартир',
        acceptanceView: 'Просмотр предписания',
        acceptanceCreate: 'Создание предписания',
        prescriptionWarranty: 'Гарантия',
        warrantyView: 'Просмотр предписания',
        warrantyCreate: 'Создание предписания',

        // Проектирование;

        designingHomeProjects: 'Проекты',
        designingHomeDrawings: 'Чертежи',
        designingHomeModels: 'Модели',
        designingModel: 'Модель',
        designingHomeTasks: 'Задачи',
        designingPlans: 'Планы',

        // Администрирование
        administration: 'Администрирование',

        administrationUsers: 'Пользователи',
        administrationUsersCreate: 'Создание пользователя',
        administrationUsersEdit: 'Редактирование пользователя',

        administrationReplacement: 'Замещения',
        administrationReplacementCreate: 'Создание замещения',
        administrationReplacementEdit: 'Редактирование замещения',

        administrationProjects: 'Проекты',
        administrationProjectsCreate: 'Создание проекта',
        administrationProjectsEdit: 'Редактирование проекта',

        administrationCities: 'Города',
        administrationCitiesCreate: 'Создание города',
        administrationCitiesEdit: 'Редактирование города',

        administrationOrganisations: 'Организации',
        administrationOrganisationsCreate: 'Создание организации',
        administrationOrganisationsEdit: 'Редактирование организации',

        administrationSections: 'Секции',
        administrationSectionsEdit: 'Редактирование секции',

        administrationFloors: 'Этажи',
        administrationFloorsEdit: 'Редактирование этажа',

        administrationApartments: 'Квартиры',
        administrationApartmentsEdit: 'Редактирование квартиры',

        administrationDesigning: 'Уведомления: Проектирование',

        // Сметы
        estimatesProjects: 'Проекты смет',
        estimatesJournal: 'Журнал смет',
        estimatesListActs: 'Реестр актов',
        estimatesEstimate: 'Смета',
        estimatesAdditional: 'Доп. смета',
        estimatesAct: 'Акт',
        estimatesContract: 'Договор',

        // Project Management
        projectManagementProjects: 'Проекты',
        projectManagementProject: 'Проект',
        projectManagementCharts: 'Графики',
        projectManagementChartsCreate: 'Создание графика',
        projectManagementChartsEdit: 'Редактирование графика',
        projectManagementChart: 'График',
        projectManagementChartBpc: 'Базовый план-график',
        projectManagementChartBpcCreate: 'Создать базовый план-график',
        projectManagementChartDpcQuart: 'Детальный план-график (квартальный ДПГ)',
        projectManagementChartDpcQuartCreate: 'Создать детальный план-график (квартальный ДПГ)',
        projectManagementChartDpcExec: 'Детальный план-график (исполнение ДПГ)',
        projectManagementChartDeadlines: 'Сроки графика (план-факт)',
        projectManagementChartWeeklyReport: 'Отчет по строительству (Недельный)',
        projectManagementReports: 'Отчетность',
        projectManagementReportsDocumentsBpc: 'Базовый план-график',
        projectManagementReportDpcExec: 'Отчет по исполнению ДПГ',
        projectManagementReportConstruction: 'Отчет по строительству (месяц/год)',
        projectManagementReportDeadlines: 'Сроки графика (план-факт)',
        projectManagementReportConsolidated: 'Сводный отчет по графикам',
    },

    components: {
        violationComponent: {
            addViolation: 'Добавить нарушение',
        },
        violationTask: {
            askDelete: 'Вы уверены, что хотите удалить?',
        },

        postponement: {
            term: 'Срок',
            descriptionCause: 'Описание причины',
            accept: 'Принять',
            reject: 'Отклонить',
            confirm: 'Подтвердить',
            viewTitle: 'Перенос срока',
            createTitle: 'Перенос срока',
            viewToggler: 'Согласование переноса срока',
            viewTogglerReject: 'Причина отклонения переноса срока',
        },
        violation: {
            formTitle: 'Форма нарушения',
            formTitleAcceptance: 'Форма замечания тех. надзора',
            formTitleAcceptanceReport: 'Отчет от подрядчика',
            formTitleAcceptancePhoto: 'Фотографии',
            formTitleAcceptanceAct: 'Акт выполненных работ (по желанию)',
            formTitleAcceptanceActRequired: 'Акт выполненных работ',
            formTitleWarrantyActRequired: 'Акт выполненных работ',
            formTitleAcceptanceRemark: 'Замечания',
            formTitleWarrantyRemark: 'Замечания',
            formTitleAcceptanceActExpert: 'Акт эксперта',
            warranty: 'Гарантийные замечания',
        },

        schemeUploader: {
            page: 'Страница',
            deleteQuestion: 'Вы уверены, что хотите удалить форму?',
            deleteTitle: 'Удаление',
            deleteAction: 'Удалить',
            uploadQuestion: 'Сохранить чертежи в системе?',
            uploadTitle: 'Сохранение',
            uploadAction: 'Сохранить',
            cancel: 'Отменить',
            uploadScheme: 'Загрузить чертеж',
            naming: 'Название',
            addPage: 'Добавить страницу',
            addPageWithCopy: 'Копировать страницу',
        },

        manager: {
            noCustomer: 'У данного проекта отсутствуют заказчик',
            noContractor: 'У данного проекта отсутствуют генподрядчик',
        },

        search: {
            placeholder: 'Поиск',
        },

        select: {
            noOptions: 'Список пуст',
            noResult: 'Элементы не найдены',
            selected: 'Выбрано',
            notSelected: 'Не выбрано',
        },

        tCloudModal: {
            tCloudName: 'T.cloud',
            addFromComputer: 'Добавить с компьютера',
            loadIntoTable: 'Загрузить в таблицу',
            removeSelection: 'Снять выделение',
            chooseAll: 'Выбрать все',
            homeDirectoryTitle: 'Главная',
            uploadSimilarVersion:
                'Файл "{name}" похож на новую версию "{similarName}", загрузить в качестве новой версии?',
            uploadNewVersion:
                'Файл с именем "{name}" уже был загружен ранее, загрузить в качестве новой версии этого файла?',
            tCloudNotification: 'Уведомление от T.Cloud',
        },

        attachModal: {
            header: 'Список документов',
            tCloudName: 'T.cloud',
            addFromComputer: 'Добавить с компьютера',
            attachFiles: 'Прикрепить файлы',
            chooseAll: 'Выбрать все',
            clearCheck: 'Снять выделение',
            needToSelectFiles: 'Необходимо выбрать документ',
            emptyList: 'Список загруженных документов пуст',
        },

        chat: {
            failedToSendMessage: 'Не удалось отправить\nсообщение',
        },

        gantt: {
            duration: 'Длительность',
            start: 'Начало',
            end: 'Окончание',
        },
    },

    plugin: {
        multiselect: {
            chooseProject: 'Выберите проект',
            chooseSubcontractor: 'Укажите субподрядчика',
            chooseExecutor: 'Выберите исполнителя',
            placeholder: {
                project: 'Выберите проект',
                schemeType: 'Выберите тип схемы',
                section: 'Выберите секцию',
                floor: 'Выберите этаж',
                apartment: 'Выберите квартиру',
            },
            select: 'Нажмите, чтобы выбрать',
            deselect: 'Нажмите, чтобы убрать',
            selected: 'Выбрано',
        },
    },

    page: {
        violationForm: {
            customer: 'Заказчик',
            generalContractor: 'Генподрядчик',
            subContractor: 'Субподрядчик',
            project: 'Проект',
            section: 'Секция',
            floor: 'Этаж',
            typeOfWorks: 'Вид работ',
            typeOfRemarks: 'Вид',
            typeOfRooms: 'Помещение',
            subcontractor: 'Субподрядчик',
            basicRequirement: 'Основание',
            deadline: 'Срок устранения',
            photos: 'Фотографии и вложения: ',
            photosUpload: 'Загрузить фото',
            photosCount: 'Количество загруженных фотографий: ',
            description: 'Описание',
            sendButton: 'Отправить',
            upload: 'Загрузить',
            selectFile: 'Выберите файл',
            statusOfRemarks: 'Статус',
            executor: 'Исполнитель',

            setGeneralContractor: 'Укажите Генподрядчика',
            setSubContractor: 'Укажите Субподрядчика',
            setProject: 'Выберите проект',
            setSection: 'Выберите секцию',
            setFloor: 'Выберите этаж',
            setTypeOfWorks: 'Выберите вид работ',
            setTypeOfRemarks: 'Не выбран',
            setTypeOfRooms: 'Не выбрано',
            setRequirement: 'Выберите положение',

            rejectCauses: 'Причина отклонения',
            postponement: 'Перенести срок',
            create: 'Создать предписание',
            createPrescription: 'Создать предписание',
            createAct: 'Сформировать акт',
            violation: 'Предписание',
            display: 'Показать',
            edit: 'Редактировать',
            cancel: 'Отменить',
            update: 'Обновить',
            copy: 'Копировать',
            violationCatalog: 'Журнал предписаний',
            violationAcceptance: 'Приёмка квартир',
            violationWarranty: 'Гарантия',
            formCreating: 'Форма создания предписания',
            saveAndClose: 'Сохранить и закрыть',
            remarkDone: 'Замечание устранено',
            createNewRemark: 'Создать новое замечание',
        },

        typeWorkComponent: {
            prescription: 'Предписание',
        },

        violation: {
            typeOfWork: 'Вид работ',
            requirement: 'Основание',
            deadline: 'Крайний срок',
            description: 'Описание',
            content: 'Содержание',
            comment: 'Комментарий',
            title: 'Нарушения',
            select: {
                chooseRequirement: 'Выберите основание',
                selectLabel: 'Нажмите, чтобы выбрать',
                deselectLabel: 'Нажмите, чтобы убрать',
            },
            requirementCustom: {
                differentRequirement: 'Другое основание',
                differentRequirements: 'Другие основания',
            },
            close: {
                done: 'Выполнить',
                completeWork: 'Описание выполненных работ',
                description: 'Описание',
            },
            reject: {
                cancel: 'Отменить',
                confirm: 'Подтвердить',
                causes: 'Причина отклонения',
                requireField: 'Поле обязательно для заполнения',
            },
        },

        close: {
            done: 'Выполнить',
        },

        filter: {
            create: 'Создать предписание',
            toggler: 'Фильтры',
            status: 'Статус',
            city: 'Город',
            project: 'Проект',
            typeOfWork: 'Вид работ',
            section: 'Секция',
            floor: 'Этаж',
            find: 'Поиск',
            clear: 'Сбросить фильтры',
            executor: 'Исполнитель',
            deadline: 'Срок устранения',
            drawingType: 'Тип схемы',
            schemeType: 'Тип схемы',
            general: 'Генеральный подрядчик',
            subcontractor: 'Субподрядчик',
            author: 'Инициатор',
            apartment: 'Квартира',
            report: 'Отчет',
            flat: 'Квартира',

            placeholder: {
                chooseStatus: 'Выберите статус',
                chooseCity: 'Выберите город',
                chooseProject: 'Выберите проект',
                chooseTypeOfWork: 'Выберите тип работ',
                chooseSection: 'Выберите секцию',
                chooseFloor: 'Выберите этаж',
                chooseExecutor: 'Выберите исполнителя',
                chooseDeadlineRange: 'Выберите крайний срок',
                chooseDrawingType: 'Выберите тип схемы',
                chooseGeneral: 'Выберите генерального подрядчика',
                chooseSubcontractor: 'Выберите субподрядчика',
                chooseAuthor: 'Выберите инициатора',
                chooseRemarkType: 'Выберите тип замечания',
                chooseCustomer: 'Выберите заказчика',
                chooseRealtyType: 'Выберите тип недвижимости',
                choosePostalNumber: 'Выберите почтовый номер',
                chooseBuildingNumber: 'Выберите строительный номер',
                chooseName: 'Выберите ФИО',
                chooseFlat: 'Выберите квартиру',
            },
        },

        photoUploader: {
            photo: 'Фотографии',
            delete: 'Удалить',
            uploadFaild: 'Не удалось загрузить изображение',
        },

        modal: {
            title: 'Уведомление от T.Pro',
            storage: 'Во время прошлой сессии произошла ошибка сети, хотите восстановить данные?',
            deletePoint: 'Вы уверены, что хотите удалить запись предписания № {index} ?',
            alert: {
                requestFail: 'Что-то пошло не так...',
                pleaseTryAgain: 'Повторите попытку позже.',
                success: 'Успешно!',
                editingDataSet: 'Изменения внесены',
            },
            choose: {
                violation: 'Предписание № {index}',
                create: 'Создать запись предписания',
            },
            answer: {
                positive: 'Да',
                negative: 'Нет',
                confirm: 'Ок',
                understand: 'Понятно',
                cancel: 'Отменить',
                copy: 'Копировать',
            },

            confirm: {
                sureToUpdate: 'Вы уверены, что хотите обновить предписание?',
            },
            success: {
                title: 'Запрос прошел успешно!',
            },
        },

        localStorage: {
            editTitle: 'Редактировать шаблон',
        },

        home: {
            name: 'Главная',
            city: 'Город',
            snip: 'Основания',
            statistics: 'Статистика',
            projectEmpty: 'Проекты отсутствуют',
            withoutSection: 'Без секций',
            projects: 'Проекты',
        },

        typeForm: {
            type: 'Вид работ',
            closeViolation: 'Закрыть предписание',
            back: 'Вернуться к форме',
            causes: 'Просмотр причины отклонения',
        },

        displayClose: {
            accept: 'Принять',
            reject: 'Отклонить',
        },

        managerSelector: {
            project: 'Проект',
            customer: 'Заказчик',
            general: 'Генподрядчик',
            subcontractor: 'Субподрядчик',
            executor: 'Исполнитель',
            dateResolve: 'Срок устранения',
            dateCreated: 'Дата обращения',
            responsiblePersons: 'Ответственные',
            projectHaveNotCustomer: 'У данного проекта отсутствует заказчик',
            projectHaveNotGeneralContractor: 'У данного проекта отсутствует генподрядчик',
        },

        fullTable: {
            status: 'Статус',
            project: 'Проект',
            type: 'Тип',
            deadlines: 'Сроки',
            violations: 'Замечания',
            violationType: 'Тип схемы',
            prescriptionType: 'Тип предписания',
            addPrescription: 'Добавить предписание',
        },

        multiselectorSlot: {
            noDataAvailable: 'Данные отсутствуют',
            elementByRequestNotFound: 'Элементов по данному запросу не найдено',
        },

        schemeSelector: {
            schemeType: {
                title: 'Тип',
                placeholder: 'Выберите тип схемы',
                select: 'Нажмите, чтобы выбрать',
                deselect: 'Нажмите, чтобы убрать',
                selected: 'Выбрано',
            },
            schemeParams: 'Параметры',
            scheme: {
                title: 'Схема',
                placeholder: 'Выберите схему',
                select: 'Нажмите, чтобы выбрать',
                deselect: 'Нажмите, чтобы убрать',
                selected: 'Выбрано',
            },
            schemeSection: {
                title: 'Схема секции',
                placeholder: 'Выберите схему',
                select: 'Нажмите, чтобы выбрать',
                deselect: 'Нажмите, чтобы убрать',
                selected: 'Выбрано',
            },
            schemeFloor: {
                title: 'Схема этажа',
                placeholder: 'Выберите схему',
                select: 'Нажмите, чтобы выбрать',
                deselect: 'Нажмите, чтобы убрать',
                selected: 'Выбрано',
            },
            schemeApartment: {
                title: 'Схема квартиры',
                placeholder: 'Выберите схему',
                select: 'Нажмите, чтобы выбрать',
                deselect: 'Нажмите, чтобы убрать',
                selected: 'Выбрано',
            },
            section: {
                title: 'Секция',
                placeholder: 'Выберите секцию',
                select: 'Нажмите, чтобы выбрать',
                deselect: 'Нажмите, чтобы убрать',
                selected: 'Выбрано',
            },
            floor: {
                title: 'Этаж',
                placeholder: 'Выберите этаж',
                select: 'Нажмите, чтобы выбрать',
                deselect: 'Нажмите, чтобы убрать',
                selected: 'Выбрано',
            },
            apartment: {
                title: 'Квартира',
                placeholder: 'Выберите квартиру',
                select: 'Нажмите, чтобы выбрать',
                deselect: 'Нажмите, чтобы убрать',
                selected: 'Выбрано',
            },
            withoutDesign: 'Чертеж не требуется',
        },

        typeSelector: {
            title: 'Тип предписания',
            placeholder: '-',
        },

        statusSelector: {
            title: 'Статус',
            placeholder: '-',
        },

        reportComment: {
            placeholder: 'Комментарий не добавлен',
        },

        responsibleSelector: {
            title: 'Ответственные',
            project: {
                title: 'Проект',
                placeholder: '-',
            },
            customer: {
                title: 'Заказчик',
                placeholder: '-',
            },
            general: {
                title: 'Генподрядчик',
                placeholder: '-',
            },
        },

        realtySelector: {
            title: 'Недвижимость',
            realtyType: {
                title: 'Тип',
                placeholder: '-',
            },
            section: {
                title: 'Секция',
                placeholder: '-',
            },
            floor: {
                title: 'Этаж',
                placeholder: '-',
            },
            postalNumber: {
                title: '№ (почт.)',
                placeholder: '-',
            },
            buildingNumber: {
                title: '№ (строит.)',
                placeholder: '-',
            },
        },

        previousSelector: {
            title: 'Предыдущие предписания',
            goToPrescription: 'Перейти к предписанию',
        },

        requestSelector: {
            title: 'Заявка',
            name: {
                title: 'ФИО',
                placeholder: '-',
            },
            address: {
                title: 'Адрес',
                placeholder: '-',
            },
            flat: {
                title: 'Квартира',
                placeholder: '-',
            },
            description: {
                title: 'Описание проблемы',
                placeholder: '-',
            },
        },

        reportSelector: {
            title: 'Отчет от подрядчика',
            comment: {
                title: 'Комментарий',
                placeholder: '-',
            },
        },

        realtyType: 'Тип недвижимости',

        dateSelector: {
            date: 'Дата',
        },

        notification: {
            title: 'У Вас нет уведомлений | У Вас {n} уведомление | У Вас {n} уведомления | У Вас {n} уведомлений',
            empty: 'Новых уведомлений нет',
            filter: {
                title: 'Уведомления',
                all: 'Все',
                unread: 'Непрочитанные',
                read: 'Прочитанные',
            },
            type: {
                System: 'Системное',
                Design: 'Проектирование',
                Violation: 'Предписания',
                Estimate: 'Сметы',
            },
            message: {
                sender: 'Т.ПРО',
                prescriptionNum: 'предписание № {n}',
                prescriptionNumChange: 'предписания № {n}',
                action: {
                    create: 'создал',
                    changeData: 'изменил данные',
                    changeStatus: 'изменил статус',
                },
            },

            eventTypes: {
                drawingStatusesChange: 'Смена статусов чертежей',
                drawingDataRolesAssignment: 'Присвоение ролей в данных по чертежу',
                drawingAnnotationsCreation: 'Создание замечаний',
                drawingAnnotationsChange: 'Изменение замечаний',
                drawingAnnotationsByGroupMove: 'Перемещение замечаний по группам',
                drawingAnnotationsFulfillment: 'Выполнение замечаний',
                drawingAnnotationsNewMessage: 'Новые сообщения в замечаниях',
                drawingExpirationDate: 'Истечение срока',
                tasksAssignment: 'Назначение задач',
                tasksDataChange: 'Редактирование данных и описания задач',
                tasksStatusChange: 'Изменение статусов задач',
                tasksNewMessages: 'Новые сообщения в задачах',
                tasksFulfillment: 'Выполнение задач исполнителями',
                tasksDeadlineChange: 'Продление сроков задач',
                tasksCreate: 'Создание задачи',
                drawingAcceptReturnRequest: 'Принятие запроса на возврат чертежа в работу',
                drawingRejectReturnRequest: 'Отклонение запроса на возврат чертежа в работу',
                drawingCreateReturnRequest: 'Создание запроса на возврат чертежа в работу',
            },

            eventSectionTitle: {
                drawingNotifications: 'Уведомления по чертежам',
                tasksNotifications: 'Уведомления по задачам',
            },
        },

        designing: {
            goToDrawings: 'Перейти к чертежам',
            goToModels: 'Перейти к моделям',
            uploadImage: 'Загрузить изображение',

            sidebar: {
                projects: 'Проекты',
                plans: 'Чертежи',
                tasks: 'Задачи',
                models: 'Модели',
            },

            sideForm: {
                tasks: 'Задачи',
                settings: 'Настройки',
                all: 'Все',
                inWork: 'В работе',
                onChecking: 'На проверке',
                forChecking: 'На проверку',
                waitingForChecking: 'Ожидает проверки',
                completed: 'Завершенные',
                completedTask: 'Завершена',
                overdue: 'Просроченные',
                accept: 'Принять',
                backInWork: 'Вернуть в работу',
                forceEnd: 'Завершить',
                perform: 'Выполнить',
                messages: 'Сообщения',
                edit: 'Редактировать',
                attach: 'Прикрепить файлы',
                prolongation: 'Продлить срок',
                goIn: 'Перейти в',
                newTask: 'Новая задача',
                deadlinePassed: 'Срок проверки чертежа истек',
                forceEndModalDescription: 'Вы уверены, что хотите принудительно завершить задачу?',
                forceEndModalTitle: 'Задача находится в работе',
                prolongationExecutorModalTitle: 'Запросить продление срока выполнения задачи',
                prolongationExecutorModalDescription: 'Если Вам нужно больше времени, попросите об этом автора задачи',
                prolongationExecutorModalReasons: 'Опишите причины...',
                prolongationExecutorModalDeadline: 'Новый срок выполнения:',
                prolongationAuthorModalTitle: 'Запрос на продление срока выполнения задачи',
                prolongationAuthorModalDescription:
                    'NAME просит продлить срок выполнения задачи до <span>NEW_DEADLINE</span>. Текущий срок выполнения - OLD_DEADLINE.',
                prolongationAuthorModalComment: 'Комментарий пользователя:',
                prolongationAuthorModalCancel: 'Отклонить',
                prolongationAuthorModalDeadline: 'Указать свой срок',
                finish: 'Завершить',
                cancel: 'Отменить',
                request: 'Запросить',
                participants: 'нет участников | {n} участник | {n} участника | {n} участников',
                chat: 'Чат',
                from: 'от',
                to: 'Кому',
                creationDate: 'Дата создания',
                deadline: 'Срок выполнения',
                taskStatus: 'Статус задачи',
                taskDescription: 'Описание задачи',
                taskExecutors: 'Исполнители задачи',
                status: {
                    overdue: 'Просрочена',
                    inWork: 'В работе',
                    onChecking: 'На проверке',
                    completed: 'Завершена',
                },
                statusExecutor: {
                    overdue: 'Просрочено',
                    inWork: 'В работе',
                    onCheck: 'На проверке',
                    accepted: 'Завершено',
                    production: 'На производстве',
                    cancelled: 'Аннулирован',
                    agreed: 'Согласовано',
                    agreedFor: 'Согласовано за ',
                    commentsFormed: 'Замечания сформированы',
                    commentsFormedFor: 'Замечания сформированы за ',
                },
                actionExecutor: {
                    accept: 'Принять',
                    backToWork: 'Вернуть в работу',
                    complete: 'Завершить',
                    completeForced: 'Завершить принудительно',
                },
                substitute: 'замещает',
                substituting: 'Замещаемый',
            },

            stages: {
                MasterPlan: 'Мастер-план',
                PlanningSurveying: 'Проект планировки и межевания территории',
                Sketch: 'Эскизный проект',
                ProjectDocumentation: 'Проектная документация',
                WorkDocumentation: 'Рабочая документация',
                Landscaping: 'Проект ландшафтного дизайна и благоустройства',
                CommonAreasDesign: 'Дизайн-проект мест общего пользования',
                PreliminaryMaterials: 'Предварительные материалы',
                Research: 'Изыскания',
            },

            modal: {
                initPlansDescription: 'Выберите проект, организацию и исполнителя, а также назначьте срок сдачи',
                selectTerm: 'Выберите срок исполнения',
                selectProject: 'Выберете проект',
                selectDesigner: 'Выберете проектировщика',
                selectExecutor: 'Выберете исполнителя',
                planSetData: 'Заполните данные по чертежу',
                modelSetData: 'Заполните данные по модели',
                planSetDataForTask: 'Заполните данные по чертежу\u000Aдля создания задачи',
                modelSetDataForTask: 'Заполните данные по модели\u000Aдля создания задачи',
                uploadPlan: 'Загрузить чертеж',
                cancel: 'Отменить',
                abort: 'Отмена',
                apply: 'Применить',
                designer: 'Проектировщик',
                project: 'Проект',
                deadline: 'Срок исполнения',
                writeTaskName: 'Напишите название задачи',
                findTaskByName: 'Найти задачу по названию',
                remarksExecutors: 'Исполнители',
                planCheckDeadline: 'Срок проверки чертежа',
                modelCheckDeadline: 'Срок проверки модели',
                commentForExecutor: 'Комментарий для исполнителя',
                addOneMoreTask: 'Добавить еще одну задачу',
                sendTasksToWork: 'добавьте задачи | Отправить задачу в работу | Отправить задачи в работу',
                next: 'Далее',
                fillLater: 'Заполню позже',
                save: 'Сохранить',
                needToFillAllData: 'Необходимо заполнить все данные',
                fillAllDataForTask: 'Чтобы опубликовать задачу, необходимо заполнить данные',
                pleaseCheckData: 'Пожалуйста, проверьте данные',
                planCheckBeforeRevision:
                    'Проверьте данные перед отправкой чертежа на доработку в проектную организацию',
                modelCheckBeforeRevision:
                    'Проверьте данные перед отправкой модели на доработку в проектную организацию',
                planCheckBeforeBackToWork: 'Проверьте данные перед возвратом чертежа в работу',
                modelCheckBeforeBackToWork: 'Проверьте данные перед возвратом модели в работу',
                fileIsLoading: 'Подождите, файл загружается',
                fileLoadedAndReadyForSending: 'Файл загружен и может быть отправлен на проверку',
                uploadNewDocumentForChecking: 'Загрузите исправленный документ для отправки на проверку',
                fileWillDuplicateAttention:
                    'Внимание! При загрузке с компьютера файл будет доступен в качестве новой версии чертежа в T.Cloud',
                planCantSendForCheckingTitle: 'Вы не можете отправить чертеж на проверку',
                modelCantSendForCheckingTitle: 'Вы не можете отправить модель на проверку',
                cantSendForCheckingDescription: 'У каждого замечания должен быть статус “Завершено” или “Отклонено”',
                planCantSendForRevisionTitle: 'Вы не можете отправить чертеж на доработку',
                modelCantSendForRevisionTitle: 'Вы не можете отправить модель на доработку',
                cantSendForRevisionDescription: 'Необходимо заполнить замечания для отправки на доработку',
                tcloudCloseConfirmTitle: 'Внесенные Вами данные не будут сохранены.\nВы уверены?',
                selectFromTCloud: 'Выбрать документ в T.Cloud',
                sureToSendToProduction: 'Вы действительно хотите отправить чертеж «В\u00A0производство»?',
                sureToSendToProductionMultiple: 'Вы действительно хотите отправить чертежи «В\u00A0производство»?',
                planSureToReturnBackToWork: 'Вы действительно хотите вернуть чертеж в работу?',
                planSureToReturnBackToWorkMultiple: 'Вы действительно хотите вернуть чертежи в работу?',
                modelSureToReturnBackToWork: 'Вы действительно хотите вернуть модель в работу?',
                modelSureToReturnBackToWorkMultiple: 'Вы действительно хотите вернуть модели в работу?',
                backToWorkUpdateDeadline: 'Пожалуйста, актуализируйте срок устранения замечаний.',
                backToWorkDeadline: 'Срок устранения замечаний:',
                successfullySentToProduction: 'Вы успешно отправили чертеж «В\u00A0производство»',
                successfullySentToProductionMultiple: 'Вы успешно отправили чертежи «В\u00A0производство»',
                successfullyReturnedBackToWork: 'Вы успешно вернули чертеж в работу',
                successfullyReturnedBackToWorkMultiple: 'Вы успешно вернули чертежи в работу',
                newVersionFound:
                    'В системе T.Cloud Была обнаружена более новая версия. Заменить текущую рабочую версию на последнюю?',
                sureToCancel: 'Вы действительно хотите аннулировать чертеж?',
                sureToCancelDescription:
                    'Вы сможете вернуть его в работу в любой момент. Все задачи по чертежу будут принудительно завершены.',
                cancelAction: 'Аннулировать',
                planSureToRequestReturnToWork: 'Вы действительно хотите запросить возврат чертежа в работу?',
                planSureToRequestReturnToWorkDescription: 'Необходимо указать причину для возврата чертежа',
                modelSureToRequestReturnToWork: 'Вы действительно хотите запросить возврат модели в работу?',
                modelSureToRequestReturnToWorkDescription: 'Необходимо указать причину для возврата модели',
                describeReasons: 'Опишите причины...',
                successfullyCanceled: 'Вы успешно аннулировали чертеж',
                planSuccessfullyRequested: 'Вы успешно запросили возврат чертежа в работу',
                modelSuccessfullyRequested: 'Вы успешно запросили возврат модели в работу',
                planSuccessfullyRejectedRequest: 'Вы успешно отменили запрос возврата чертежа в работу',
                modelSuccessfullyRejectedRequest: 'Вы успешно отменили запрос возврата модели в работу',
                remarkRejectionModalTitle: 'Вы действительно хотите отклонить замечание?',
                remarkRejectionModalDescription: 'Для отклонения замечания необходимо указать причины отклонения.',
                remarkRejectionModalReasons: 'Опишите причины...',
                remarkRejectionResetModalTitle: 'Уверены, что хотите сбросить статус “Отклонено”?',
                remarkRejectionResetModalDescription:
                    'После сброса статуса “Отклонено” причина останется в чате замечания',
                remarkRejectionChangeModalTitle: 'Изменить причину отклонения',
                remarkRejectionChangeModalDescription: 'Внесите исправления в текст и нажмите “изменить”',
                remarkRejectionSendModalTitle: 'Все замечания по документу отклонены',
                remarkRejectionSuccessModalTitle: 'Документ успешно отправлен на проверку',
                planGoToList: 'Перейти к списку чертежей',
                planStay: 'Остаться в этом чертеже',
                modelGoToList: 'Перейти к списку моделей',
                modelStay: 'Остаться в этой модели',
            },

            table: {
                name: 'Наименование',
                ID: 'ID',
                status: 'Статус',
                project: 'Проект',
                customer: 'Куратор со стороны Заказчика',
                authors: 'Представители Заказчика',
                customerManager: 'Региональный руководитель со стороны Заказчика',
                projectOrganisation: 'Проектная организация',
                generalDesigners: 'Проектные организации',
                organisationRepresentative: 'Куратор со стороны Проектной организации',
                organisationExecutor: 'Представители Проектной организации',
                generalDesignerManager: 'Главный специалист со стороны Проектной организации',
                planStage: 'Стадия чертежа',
                modelStage: 'Стадия модели',
                fixingDeadline: 'Срок устранения замечаний',
                checkingDeadline: 'Срок проверки',
                downloadDate: 'Дата загрузки',
                author: 'Автор',
                acceptDate: 'Дата принятия',
                createTask: 'Создать задачу',
                download: 'Скачать',
                remove: 'Удалить',
                planOpen: 'Открыть чертеж',
                modelOpen: 'Открыть модель',
                planData: 'Данные чертежа',
                modelData: 'Данные модели',
                planTasks: 'Задачи чертежа',
                modelTasks: 'Задачи модели',
                changelog: 'Журнал изменений',
                sureToRemove: 'Уверены, что хотите удалить чертеж из списка?',
                cancel: 'Отменить',
                alert: 'Выберите чертеж',
                cancelSelection: 'Отменить выбор',
                remarksDeadline: 'Срок устранения замечаний',
                planCheckDeadline: 'Срок проверки чертежа',
                modelCheckDeadline: 'Срок проверки модели',
                backToWork: 'Вернуть в работу',
                toProduction: 'В производство',
                nullify: 'Аннулировать',
                planAdd: 'Добавить чертеж',
                modelAdd: 'Добавить модель',
            },

            filter: {
                status: 'Статус',
                remarkAuthor: 'Представители Заказчика',
                organisationRepresentative: 'Кураторы Проектной организации',
                organisationExecutor: 'Представители Проектной организации',
                project: 'Проект',
                customerPresenter: 'Кураторы Заказчика',
                addPlan: 'Добавить чертеж',
                addModel: 'Добавить модель',
                executor: 'Исполнители задачи',
                author: 'Авторы задачи',
                createdDateFrom: 'Дата создания от',
                createdDateTo: 'Дата создания до',
                deadlineFrom: 'Срок выполнения от',
                deadlineTo: 'Срок выполнения до',
                taskStatus: 'Статус задачи',
                executorStatus: 'Статус исполнителя в задаче',
                customerManager: 'Региональный руководитель Заказчика',
                generalDesignerManager: 'Главный специалист Проектной организации',
            },

            messages: {
                noComments: 'Нет комментариев',
                newComments: 'Нет комментариев | +{n} комментарий | +{n} комментария | +{n} комментариев',
            },

            status: {
                new: 'Новый',
                checking: 'На проверке',
                fixing: 'На доработке',
                adopted: 'Принят',
                inProduction: 'В\u00A0производстве',
                cancel: 'Аннулирован',
                requestReturn: 'Запрос возврата в работу',
            },

            action: {
                requestReturn: 'Запросить возврат в работу',
                rejectRequestReturn: 'Отклонить возврат в работу',
            },

            plans: {
                tooltips: {
                    move: 'Перемещение',
                    remarkArea: 'Область замечания',
                    remarkPin: 'Метка замечания',
                    selectRemark: 'Выбор замечания',
                    planActions: 'Действия с чертежом',
                    modelActions: 'Действия с моделью',
                    openPagesPreview: 'Открыть превью страниц',
                    pageNumber: 'Номер страницы',
                    hideForm: 'Скрыть форму',
                    openForm: 'Раскрыть форму',
                    nextPage: 'Следующая страница',
                    prevPage: 'Предыдущая страница',
                    adjustHeight: 'Подогнать по высоте',
                    adjustWidth: 'Подогнать по ширине',
                    removeFromComparing: 'Удалить из сравнения',
                    zoomIn: 'Приблизить',
                    zoomOut: 'Отдалить',
                    versionsList: 'Список версий',
                },
                form: {
                    attach: 'Прикрепить файл',
                    nothingHere: 'Здесь пока ничего нет',
                    search: 'Поиск',
                    all: 'Все',
                    pgs: 'стр.',
                    minAgo: 'мин. назад',
                    status: 'Статус',
                    removeRemark: 'Удалить замечание',
                    removeGroup: 'Удалить группу',
                    editRemark: 'Редактировать замечание',
                    swap: 'Переместить',
                    noMessages: 'Здесь пока нет сообщений',
                    sureToRemoveRemark: 'Действительно хотите удалить замечание?',
                    sureToRemoveGroup: 'Действительно хотите удалить группу "{name}"?',
                    cancel: 'Отменить',
                    remove: 'Удалить',
                    relocateIn: 'Переместить в',
                    remark: 'Замечание',
                    setPinForClonedRemark: 'Поставьте отметку на чертеже, чтобы подтвердить копирование',
                    addRemarkText: 'Добавить текст замечания',
                    remarkRejectionReasonTitle: 'Причина отклонения:',
                },
                editor: {
                    compare: 'Сравнить версии',
                    returnToWork: 'Вернуть в работу',
                    forRevision: 'На устранение замечаний',
                    forChecking: 'На проверку',
                    accept: { plan: 'Принять чертеж', model: 'Принять модель' },
                    createNewRemark: 'Создать замечание в новой группе',
                    pasteRemark: 'Вставить замечание',
                    cancel: 'Отменить',
                    send: 'Отправить',
                    selectDesigner: 'Выберите проектировщика',
                    selectExecutor: 'Выберите исполнителя',
                    selectTerm: 'Выберите срок исполнения',
                    sendForRevision: 'Отправить документ на доработку?',
                    setParamsForRevision: 'Выберите организацию и исполнителя, а также назначьте срок сдачи',
                    successSend: 'Документ успешно отправлен на доработку',
                    successAccept: 'Документ успешно принят',
                    successSendForChecking: 'Документ успешно отправлен на проверку и добавлен в T.Cloud',
                    errorSendForChecking: 'Ошибка: необходима новая версия чертежа',
                    goToProjects: 'Перейти к списку проектов',
                    stayThisProject: 'Остаться в этом проекте',
                    group: 'Группа',
                    addIn: 'Создать замечание в',
                    copyOnNew: 'Копировать на новый',
                    addFileFromComparing: 'Добавить файл для сравнения',
                    fillLastRemark: 'Сначала заполните предыдущее замечание',
                    checkingDeadline: 'Срок проверки чертежа',
                    inProduction: 'В производство',
                    notSupported: 'Пока не поддерживается!',
                },
                backToTask: 'Назад к задаче',
            },

            changelog: {
                title: 'Журнал изменений',
                toFixing: 'Отправил(а) чертеж на доработку',
                toChecking: 'Отправил(а) чертеж на проверку',
                toAdopted: 'Принял(а) чертеж',
                toProduction: 'Отправил(а) чертёж в производство',
                toCreated: 'Создал(а) чертёж',
                toCancel: 'Аннулировал(а) чертёж',
            },

            task: {
                requestedProlongation: 'Запросил(а) продление срока',
                executorsList: 'Список исполнителей',
                executorsListIsEmpty: 'Исполнители не добавлены',
                addExecutor: 'Добавить исполнителя',
                createReport: 'Сформировать отчет',
                createReportAction: 'Сформировать',
                createReportModalTitle: 'Заполните параметры для формирования отчета',
                createReportFromDate: 'Начало отчетного периода:',
                createReportToDate: 'Окончание отчетного периода:',
                createReportFileName: 'Статистика по задачам с {0} по {1}.xlsx',
                createReportFileNameAcceptance: 'Отчёт по приёмке с {0} по {1}.xlsx',
                createReportFileNameWarranty: 'Отчёт по гарантии с {0} по {1}.xlsx',
                createReportVerification: 'Отчёт о нарушении сроков проверки/устранения нарушений с {0} по {1}.xlsx',
            },
        },

        estimates: {
            journal: {
                title: 'Журнал смет',

                byNumber: 'По номеру',
                byDate: 'По дате',

                name: 'Наименование',
                number: 'Номер',
                cost: 'Стоимость',
                estimator: 'Последнее изменение:\nсметчик',
                contractor: 'Последнее изменение:\nконтрагент',
                numberDays: 'Количество дней\nна согласовании',

                openEstimate: 'Открыть смету',
                notFound: 'По указанным параметрам фильтров документы не найдены',
            },

            listActs: {
                title: 'Реестр актов',
                sum: 'Итого по актам',
                count: 'Количество актов',
            },

            projects: {
                header: 'Мои проекты',
                code: 'Код',

                all: 'Все',
                active: 'Активные',
                completed: 'Завершенные',

                estimate: 'Сметы',
                additional: 'Доп.сметы',
                act: 'Акты',
                contract: 'Договора',
            },

            estimate: {
                title: 'Смета',
                positions: 'Позиции сметы',
                additionalEstimates: 'Доп.сметы',
                acts: 'Акты',

                expenses: 'Статьи затрат',
                expense: 'Статья\nзатрат',
                pp: 'п/п',
                naming: 'Наименование',
                currentNaming: 'Текущее наименование',
                level: 'Уровень',
                remains: 'Остаток',
                remainsInfo: 'Остаток кол-ва материалов/работ <br/> и суммы по смете',
                units: 'Ед.измерения',
                unitsShort: 'Ед.изм.',
                fullCost: 'Общая\nстоимость',
                expenditure: 'Расход\nна ед.',
                note: 'Примечание',
                characteristic: 'Характеристика',
                agreedCount: 'Согл.\nобъем',
                uploadInExcel: 'Выгрузить в Excel',
                createNewAct: 'Создать новый акт',
                createAdditionalEstimate: 'Создать доп.смету',
                downloadExcel: 'Действительно хотите скачать смету в формате Excel на свой компьютер?',

                totalCost: 'Итоговая стоимость работ и материалов с НДС',
                totalCostRemuneration: 'Всего по смете с учетом вознаграждения, включая НДС',
                withMaterials: 'В том числе материалы',
                withVAT: 'В том числе НДС 20%',
                contractRemuneration: 'Вознаграждение по договору',
                open: 'Открыть смету',
                data: 'Данные сметы',
            },

            contract: {
                title: 'Договор',
                positions: 'Позиции договора',
                additionalEstimates: 'Доп.сметы',
                acts: 'Акты',

                expenses: 'Статьи затрат',
                expense: 'Статья затрат',
                pp: 'п/п',
                naming: 'Наименование',
                level: 'Уровень',
                remains: 'Остаток',
                remainsInfo: 'Остаток кол-ва материалов/работ <br/> и суммы по смете',
                units: 'Ед.измерения',
                unitsShort: 'Ед.изм.',
                fullCost: 'Общая\nстоимость',
                expenditure: 'Расход\nна ед.',
                note: 'Примечание',
                characteristic: 'Характеристика',
                uploadInExcel: 'Выгрузить в Excel',
                createNewAct: 'Создать новый акт',
                createAdditionalEstimate: 'Создать доп.смету',
                downloadExcel: 'Действительно хотите скачать договор в формате Excel на свой компьютер?',
                totalCost: 'Итоговая стоимость работ и материалов с НДС',
                totalCostRemuneration: 'Всего по смете с учетом вознаграждения, включая НДС',
                withMaterials: 'В том числе материалы',
                withVAT: 'В том числе НДС 20%',
                contractRemuneration: 'Вознаграждение по договору',
                open: 'Открыть договор',
                data: 'Данные договора',
            },

            additional: {
                title: 'Доп. смета',
                positions: 'Позиции доп.сметы',
                acts: 'Акты',
                uploadInExcel: 'Выгрузить в Excel',
                downloadTemplate: 'Выгрузить шаблон доп.сметы',
                addDataFromExcel: 'Загрузить из Excel',
                sync1c: 'Проверить в 1С',
                addDataHint: 'Очистить позиции и загрузить<br/>новые из файла Excel',
                remarks: 'Замечания',
                delete: 'Вы действительно хотите удалить доп.смету',
                downloadExcel: 'Действительно хотите скачать доп.смету в формате Excel на свой компьютер?',
                sureDownloadTemplate: 'Действительно хотите скачать шаблон доп.сметы на свой компьютер?',
                placeholder:
                    'Создайте доп.смету, чтобы начать ее заполнение <br/> для дальнейшей отправки на согласование',
                placeholderNoRights: 'У вас нет прав для создания доп.смет',
                placeholderExcel: 'Загрузите файл Excel, чтобы автоматически<br/> заполнить данные доп.сметы',
                send: 'Хотите отправить доп.смету',
                open: 'Открыть доп.смету',
                data: 'Данные доп.сметы',
                sendToSyncSuccess: 'Доп.смета успешно отправлена в 1с',
                sendToReviewSuccess: 'Доп.смета успешно отправлена на проверку',
                sendToRevisionSuccess: 'Доп.смета успешно возвращена на доработку',
                sendToCancellationSuccess: 'Доп.смета успешно отменена',
                sendToApprovalSuccess: 'Доп.смета успешно согласована',
            },

            act: {
                title: 'Акт',
                positions: 'Позиции акта',
                uploadInExcel: 'Выгрузить в Excel',
                downloadTemplate: 'Выгрузить шаблон акта',
                addDataFromExcel: 'Загрузить из Excel',
                sync1c: 'Проверить в 1С',
                addDataHint: 'Очистить позиции и загрузить<br/>новые из файла Excel',
                delete: 'Вы действительно хотите удалить акт',
                downloadExcel: 'Действительно хотите скачать акт в формате Excel на свой компьютер?',
                sureDownloadTemplate: 'Действительно хотите скачать шаблон акта на свой компьютер?',
                placeholder: 'Создайте акт, чтобы начать его заполнение <br/> для дальнейшей отправки на согласование',
                placeholderNoRights: 'У вас нет прав для создания актов',
                placeholderExcel: 'Загрузите файл Excel, чтобы автоматически<br/> заполнить данные акта',
                send: 'Хотите отправить акт',
                open: 'Открыть акт',
                data: 'Данные акта',
                sendToSyncSuccess: 'Акт успешно отправлен в 1с',
                sendToReviewSuccess: 'Акт успешно отправлен на проверку',
                sendToRevisionSuccess: 'Акт успешно возвращен на доработку',
                sendToCancellationSuccess: 'Акт успешно отменен',
                sendToApprovalSuccess: 'Акт успешно согласован',
            },

            excelTransferModal: {
                startTransferring: 'Начать перемещение',
                pleaseWaitTransferring: 'Пожалуйста, подождите, перемещение займет примерно 10 секунд',
                uploadExcelFromYourComputer:
                    'Загрузите Excel с вашего компьютера для автоматического перемещения позиций в документ',
                fileIsUploading: 'Подождите, файл загружается',
                fileIsUploaded: 'Файл загружен, можете начать перемещение данных в документ',
                errorWhileUploading: 'Ошибка при загрузке',
                errorWhileTransferring: 'Ошибка при перемещении',
                excelUploading: 'Загрузка файла Excel',
                transferringSubtitle: 'После заполнения все данные из Excel перенесутся в таблицу доп.сметы',
                uploadingError:
                    'Не удалось загрузить файл. Пожалуйста, повторите попытку загрузки или загрузите новый файл',
                transferringError:
                    'Произошла ошибка при перемещении данных из Excel. Повторите попытку, чтобы переместить данные из Excel',
                dataTransferred: 'Данные успешно перемещены',
                goToExtraEstimate: 'Перейти к документу',
            },

            generalPositionCatalogModal: {
                title: 'Общий каталог позиций',
                searchPlaceholder: 'Поиск по позициям',
                cancelChoise: 'Отменить выбор',
                addToExtraEstimate: 'Добавить в доп.смету',
                createNewPosition: 'Создать новую позицию',
                chooseItemToAddInExtraEstimate: 'Выберите пункт, чтобы добавить в доп.смету',
            },

            updateDataModal: {
                customerOrganization: 'Организация сметчика',
                documentNumber: 'Номер документа',
                generalContractorOrganizations: 'Организации контрагента',
                date: 'Дата составления',
                needToFillAllData: 'Необходимо заполнить все данные',
                fill: 'Заполните',
            },

            createNewPosition: {
                title: 'Создание новой позиции',
                naming: 'Наименование позиции',
            },

            documentType: {
                estimate: 'Основная смета',
                additional: 'Доп.смета',
                act: 'Акт',
                contract: 'Договор',
            },

            status: {
                draft: 'Черновик',
                canceled: 'Не согласован',
                checking: 'На проверке',
                fixing: 'На доработке',
                agreed: 'Согласован',
                agreedBySupervisor: 'Согласован ТН',
                agreedByProjectManager: 'Согласован РП',
            },

            projectStatus: {
                active: 'Активный',
                completed: 'Завершен',
            },

            part: {
                local: 'Локальная смета',
                chapter: 'Раздел',
                subchapter: 'Подраздел',
                job: 'Работа',
                material: 'Материал',
                LocalSmeta: 'Локальная смета',
                ChapterSmeta: 'Раздел',
                SubchapterSmeta: 'Подраздел',
                JobSmeta: 'Работа',
                MaterialSmeta: 'Материал',
            },

            sendFor: {
                review: 'Отправить на проверку',
                revision: 'Вернуть на доработку',
                cancellation: 'Отменить',
                approval: 'Согласовать',
            },

            action: {
                review: 'на проверку',
                revision: 'на доработку',
                cancellation: 'для отмены',
                approval: 'для согласования',
                sync: 'на проверку в 1С',
            },

            tooltip: {
                isOutOfSync: 'Отсутствует в справочнике 1C',
                isMismatched: 'Не соответствует сметной строке',
            },

            positionEdit: {
                title: 'Позиция сметы',
                action: 'Редактировать позицию',
            },

            history: 'Журнал изменений',
        },

        administration: {
            fieldNameLabel: 'Введите название',
            findByINN: 'Заполнить по ИНН',

            cityCreated: 'Город успешно создан',
            organisationCreated: 'Организация успешно создана',
            projectCreated: 'Проект успешно создан',

            noRoles: 'Нет ролей',
            noPosition: 'Нет должности',
            addressNotStated: 'Адрес не указан',
            cityNotStated: 'Город не указан',

            chooseProjectFromList: 'Выберите проект из списка',
            chooseUsersFromList: 'Выберите пользователей из списка',
            userDeleted: 'Пользователь удален',
            addToNewProject: 'Добавить в новый проект',
            userProjects: 'Проекты пользователя',
            usersProjectEmptyTableMessage: 'Пользователь не состоит ни в одном проекте',
            sureRevokePowers: 'Действительно хотите отозвать полномочия пользователя?',
            sureToDeleteUser: 'Вы действительно хотите удалить пользователя {0}',
            invitationSentToEmail: 'Приглашение в систему было отправлено пользователю на e-mail «{0}»',

            organisationCreate: 'Создать секцию',
            organisationCreating: 'Создание секции',
            organisationEditing: 'Редактирование секции',
            organisationName: 'Название секции',
            organisationNamePlaceholder: 'Например, «Секция №1»',
            organisationSubEmptyTableMessage: 'У вас нет добавленных суб. подрядчиков',
            organisationGenEmptyTableMessage: 'У вас нет добавленных ген. подрядчиков',
            organisationSubModalTitleMessage: 'Выберите суб. подрядчика из списка',
            organisationGenModalTitleMessage: 'Выберите ген. подрядчика из списка',
            organisationSubModalLabelMessage: 'Выберите суб. подрядчика',
            organisationGenModalLabelMessage: 'Выберите ген. подрядчика',

            sectionCreate: 'Создать секцию',
            sectionCreating: 'Создание секции',
            sectionEditing: 'Редактирование секции',
            sectionName: 'Название секции',
            sectionEmptyTableMessage: 'Вы можете создать секцию к этому проекту',
            sectionNamePlaceholder: 'Например, «Секция №1»',

            floorCreate: 'Создать этаж',
            floorCreating: 'Создание этажа',
            floorEditing: 'Редактирование этажа',
            floorName: 'Название этажа',
            floorEmptyTableMessage: 'Вы можете создать этаж в этой секции',
            floorNamePlaceholder: 'Например, «Этаж №1»',

            apartmentCreate: 'Создать квартиру',
            apartmentCreating: 'Создание квартиры',
            apartmentEditing: 'Редактирование квартиры',
            apartmentName: 'Название квартиры',
            apartmentEmptyTableMessage: 'Вы можете создать квартиру в этом этаже',
            apartmentNamePlaceholder: 'Например, «Квартира №1»',

            sureToDeleteReplacement: 'Вы действительно хотите удалить замещение {0} на {1}?',
            sureToStopReplacement: 'Действительно хотите завершить замещение прямо сейчас?',
            replacementWillStopNow: 'Замещение для {0} с {1} по {2} будет завершено сейчас.',
            replacementStopped: 'Замещение завершено.',
            replacementDeleted: 'Замещение удалено.',
        },

        acceptance: {
            deadline: 'Срок устранения замечаний',
            prescriptionNum: 'Предписание № {n}',
            areYouCompleteRemarks: 'Вы действительно устранили замечания?',
            areYouCompleteRemarksDescription:
                'Клиент получит уведомление, что все его замечания устранены, и будет приглашен на повторную приемку.',
            internalTerm: 'Срок (не виден клиентам)',
            eliminationDates: 'Даты устранения',
            remarksFrom: 'Замечания от',
            responsible: 'Ответственные',
            descriptionOfImplementedWorks: 'Описание выполненных работ...',
            error: 'Чтобы создать предписание, добавьте хотя бы 1 замечание',
            resolveRemarks: 'Вы действительно устранили замечание?',
            cancelRemarks: 'Вы действительно хотите отменить замечание?',
            copyRemarks: 'Вы действительно хотите скопировать все непринятые замечания из предписания №',
            copyRemarksError: 'В данном предписании отсутствуют отклоненные замечания',
        },

        warranty: {
            actCreated: 'Акт успешно сформирован',
        },

        projectManagement: {
            title: 'Управление проектами',

            projects: {
                menu: 'Проекты',
                title: 'Мои проекты',
                code: 'Код',
                uploadImage: 'Загрузить изображение',
            },

            status: {
                planned: 'Запланирован',
                approved: 'Утвержден',
                stopped: 'Остановлен',
                completed: 'Завершен',
                prepared: 'Подготовлен',
                agreed: 'Согласован',
                onApproval: 'На согласовании',
                notAgreed: 'Не согласован',
                decidingLaunch: 'Принимается решение о запуске',
                designStarted: 'Запущено проектирование',
                constructionUnderway: 'Ведется строительство',
                workStopped: 'Работы остановлены',
            },

            calendarType: {
                fiveDays: 'Рабочий календарь на год (5 дневка)',
                sevenDays: 'Рабочий календарь на год (7 дневка)',
            },

            project: {
                globalStatus: {
                    active: 'Активный',
                    completed: 'Завершен',
                },
                subdevision: {
                    novation: 'Новация',
                    design: 'Проектирование',
                    tenders: 'Тендеры',
                    construction: 'Строительство',
                    seo: 'СЭО',
                    consolidated: 'Сводный',
                },
            },

            charts: {
                menu: 'Графики',
                title: 'Графики',
                add: 'Добавить график',
                openDocument: 'Открыть документ',
                chartName: 'Наименование графика',
            },

            chart: {
                menu: 'Графики',
                subjectToApprovalEDO: 'На согласовании в ЭДО',
                approvedEDO: 'Согласован в ЭДО',
                change: 'Изменить информацию',
                versionCreation: 'Создание версии',
                versionEdit: 'Изменение версии',
                versionName: 'Наименование версии',
                notChosen: 'Не выбрано',

                baseInfo: 'Основная информация',
                extraInfo: 'Дополнительная информация',
                introInfo: 'Вводная информация',
                chartInfo: 'Сведения о графике',
                objectGeneralPlan: 'Общий план объекта',
                versions: 'Версии графика',
                connectTelegram: 'Подключить график к Telegram',
                structureWillRewrite: 'Структура {0} версии будет перезаписана',
                structureWillRewriteDesc: 'Структура графика “{1}” будет заменена на структуру графика “{0}”.',
                structureWillRewriteDescCurr:
                    ' Перед загрузкой рекомендуем создать резервную копию текущей версии, так как данные из неё будут удалены безвозвратно.',
                structureDidRewrite: 'Структура {0} версии успешно перезаписана',
                structureWillRewriteDescChosen: ' Предыдущие данные выбранной версии будут потеряны.',
                structureRewriteData: 'Данные взяты из {0}.',
                wantDelete: 'Вы действительно хотите безвозвратно удалить документ {0}?',
                didDelete: 'Документ {0} успешно удален',
                canNotGoReport: 'Невозможно перейти в раздел',
                canNotGoReportDesc: 'Чтобы перейти в данный раздел, создайте структуру графика',
                fileUploading: 'Загрузка файла',
                uploadingError: 'Ошибка при загрузке',
                waitUploading: 'Подождите, файл загружается',
                fileIsUploaded: 'Файл загружен',
                addToReport: 'Добавить в отчет',

                stage: 'Стадия работ',
                calendar: 'Календарь проекта',
                manager: 'Руководитель проекта',
                designer: 'Куратор проекта',
                periodNotChosen: 'Отчетный период не выбран',
                docNotChosen: 'Документ основание не выбран',
                successfullySaved: 'График успешно сохранен',

                bpc: {
                    tab: 'БПГ',
                    title: 'Базовый план-график',
                },

                dpcQuart: {
                    tab: 'Квартальный ДПГ',
                    title: 'Детальный план-график (квартальный ДПГ)',
                    dateBeginLabel: 'Начало отчетного периода',
                    dateEndLabel: 'Окончание отчетного периода',
                },

                dpcExec: {
                    tab: 'Исполнение ДПГ',
                    title: 'Детальный план-график (исполнение ДПГ)',
                    datesLabel: 'Квартальный ДПГ (документ основание)',
                },

                weeklyReport: {
                    tab: 'Недельный отчет',
                    title: 'Отчет по строительству (Недельный)',
                    constructionProgressTitle: 'Общие фото объекта на момент предоставления отчета по схеме:',
                    countedTab: {
                        introInfo: '1. Вводная информация',
                        constructionProgress: '2. Ход строительства',
                        jobTypes: '2.1 Типы работ',
                    },
                },

                modal: {
                    titleRewrite: 'Данные документа будут перезаполнены',
                    titleSend: 'Отправить документ на согласование?',
                },

                action: {
                    stop: 'Остановить выполнение',
                    resume: 'Возобновить выполнение',
                    complete: 'Завершить выполнение',
                },

                type: {
                    title: 'Вид графика',
                    consolidated: 'Сводный',
                    design: 'Проектирование',
                    construction: 'Строительство',
                    tenders: 'Тендеры',
                },
            },

            document: {
                menu: 'Документы',
                title: 'Документы',
                bpc: { title: 'Базовый план-график' },
                dpcQuart: { title: 'Детальный план-график (квартальный ДПГ)' },
                dpcExec: { title: 'Детальный план-график (исполнение ДПГ)' },
                weeklyConstruction: { title: 'Отчет по строительству (недельный)' },
            },

            reports: {
                menu: 'Отчетность',
                title: 'Отчетность',
            },

            report: {
                title: 'Отчетность',
                dpcExec: {
                    title: 'Отчет по исполнению ДПГ',
                    periodFrom: 'Период от',
                    periodUntil: 'Период до',
                    deviationChart: 'График отклонения от буфера',
                },
                construction: {
                    title: 'Отчет по строительству (месяц/год)',
                    shortTitle: 'Отчет по строительству',
                    realization: 'Отчет о ходе реализации строительства ЖК',
                    objectStateStart: 'Объект на начало периода',
                    objectStateFinish: 'Объект на конец периода',
                    bufferDynamic: 'Динамика буфера критического пути',
                },
                consolidated: {
                    title: 'Сводный отчет по графикам',
                    shortTitle: 'Сводный отчет',
                },
                deadlines: {
                    title: 'Сроки графика (план-факт)',
                    shortTitle: 'Сроки графика',
                },
                bpc: {
                    title: 'Базовый план-график',
                    name: 'Название БПГ',
                    base: 'Базовая',
                },
                dpcQuart: {
                    title: 'Детальный план-график (квартальный ДПГ)',
                    name: 'Название ДПГ',
                },
                weeklyReport: {
                    title: 'Отчет по строительству (Недельный)',
                    name: 'Недельный отчет',
                },
            },

            filter: {
                startFrom: 'Дата старта от',
                startTo: 'Дата старта до',
                beginFrom: 'Дата начала от',
                beginTo: 'Дата начала до',
                beginBPFrom: 'Дата начала по БП от',
                beginBPTo: 'Дата начала по БП до',
                endFrom: 'Дата завершения от',
                endTo: 'Дата завершения до',
                endBPFrom: 'Дата завершения по БП от',
                endBPTo: 'Дата завершения по БП до',
                durationFrom: 'Длительность от',
                durationTo: 'Длительность до',
                prognosisDurationFrom: 'Длительность (прогноз) от',
                prognosisDurationTo: 'Длительность (прогноз) до',
                readinessFrom: '%гот. от',
                readinessTo: '%гот. до',
                prognosisReadinessFrom: '%гот. (прогноз) от',
                prognosisReadinessTo: '%гот. (прогноз) до',
                deviationBeginFrom: 'Отклонение начала от',
                deviationBeginTo: 'Отклонение начала до',
                deviationEndFrom: 'Отклонение завершения от',
                deviationEndTo: 'Отклонение завершения до',
                showOnlyCurrent: 'Отображать только текущие задачи',
            },

            tab: {
                chartInfo: 'Информация о графике',
                structure: 'Структура',
                bpc: 'БПГ',
                dpcQuart: 'Квартальный ДПГ',
                dpcExec: 'Исполнение ДПГ',
                weeklyReport: 'Недельный отчет',
            },

            createEdit: {
                goStructure: 'Перейти к созданию структуры',
                goChart: 'Перейти к графику',
                successTitle: 'График успешно создан',
                leaveTitle: 'Вы действительно хотите выйти?',
                leaveDescription: 'Данные графика не будут сохранены',
            },

            action: {
                createDoc: 'Создать документ',
                uploadToExcel: 'Выгрузить в Excel',
                makeBase: 'Сделать базовой',
                sendForApproval: 'Отправить на согласование',
                formDoc: 'Сформировать',
                formDocFromChart: 'Заполнить по графику',
                filter: 'Отфильтровать',
            },

            role: {
                approvingUsers: 'Согласующие пользователи',
                conformingUsers: 'Утверждающие пользователи',
                mainEngineer: 'Главный инженер',
                branchDirector: 'Директор филиала',
                regionalHead: 'Региональный руководитель по строительству',
                constructionDirectorDeputy: 'Заместитель генерального директора по строительству',
                generalDirectorDeputy: 'Первый заместитель генерального директора',
            },

            placeholder: 'Вы еще не сформировали',

            column: {
                title: 'Название',
                codeSDR: 'Код СДР',
                beginning: 'Начало',
                ending: 'Окончание',
                reportPeriod: 'Отчетный период',
                reportPeriodBaseDoc: 'Отчетный период (документ основание)',
                id: 'ID',
                percentReadiness: 'Процент\nготовности\nпрогноз',
                completenessPlan: '%\n(план)',
                startDatePlan: 'Начало\n(план)',
                endDatePlan: 'Завершение\n(план)',
                completenessFact: '%\n(факт)',
                startDateFact: 'Начало\n(факт)',
                endDateFact: 'Завершение\n(факт)',
                completenessGeneral: 'Выполнение\nKi, %',
                N: '№',
                percentReadyPlan: '% гот.\n(план)',
                percentReadyFact: '% гот.\n(факт)',
                addedPhotos: 'Добавлено\nфотографий',
                jobsDesc: 'Описание работ',
                bufferDeviation: 'Отклонение от буфера',
                buffer: 'Буфер',
                duration: 'Длительность',
                beginBPAt: 'Начало по БП',
                endBPAt: 'Завершение\nпо БП',
                durationWD: 'Длительность\n(рабочие дни)',
                durationPrognosisWD: 'Длительность\n(рабочие дни,\nпрогноз)',
                percentReady: '% гот.',
                percentReadyPrognosis: '% гот.\n(прогноз)',
                executionDpc: 'Исполнение\nДПГ',
                deviationBegin: 'Отклонение\nначала',
                deviationEnd: 'Отклонение\nзавершения',
                cityProject: 'Город / Проект / Вид графика / Наименование графика',
                stage: 'Стадия работ',
                bpcName: 'Название БПГ',
                base: 'Базовая',
                acceptedDate: 'Дата согласования',
            },

            structure: {
                buttons: {
                    cancelSelection: 'Отменить выбор',
                    createTask: 'Создать задачу',
                    basePlan: 'Базовый план-график',
                    copy: 'Дублировать',
                    moveUp: 'Переместить вверх',
                    moveDown: 'Переместить вниз',
                    riseLevel: 'Повысить уровень задачи',
                    lowerLevel: 'Понизить уровень задачи',
                    percentReady: '% гот.',
                    table: 'Таблица',
                    ganttChart: 'Диаграмма Ганта',
                },
                headers: {
                    name: 'Название',
                    code: 'Код СДР',
                    connections: 'Связи',
                    chartsConnections: 'Связи м/у графиками',
                    weight: 'Вес',
                    ready: '% гот.',
                    duration: 'Длительность',
                    beginning: 'Начало',
                    ending: 'Окончание',
                    endingDeviation: 'Отклонение по окончанию ',
                    beginningBP: 'Начало по БП',
                    endingBP: 'Окончание по БП',
                },
                actions: {
                    createTaskUpper: 'Создать задачу выше',
                    createTaskLower: 'Создать задачу ниже',
                    createTaskInner: 'Создать задачу внутри',
                },
            },
        },
    },

    homeMenu: {
        name: 'Проект',
        back: 'Назад',
        sample: 'Пример кнопки',
    },

    homeCities: {
        objects: 'Объекты',
        sections: 'Секции',
        object: 'Объект',
        section: 'Секция',
        statistics: 'Статистика',
        specifications: 'Спецификации',
    },

    table: {
        empty: 'Предписания отсутствуют',
        tableEmpty: 'Таблица пуста',
    },

    modal: {
        accept: 'Принять',
        errors: {
            error: 'Ошибка',
            server: 'Не удалось отправить данные на сервер',
        },
    },

    errors: {
        error: 'Во время обращения к серверу произошла ошибка',
        typeOfWork: 'Не выбран вид работ предписания № {points}',
        typeOfWorkAcceptance: 'Не выбран вид работ',
        typeOfRoomAcceptance: 'Не выбрано помещение',
        description: 'Не указано описание предписания № {points}',
        content: 'Не заполнено содержание',
        photo: 'Не загружены фотографии',
        failedToUpload: 'Не удалось получить файл, попробуйте еще раз.',
        fileTooLarge: 'Слишком большой файл. Принимаем не больше {maxFileSize}',
        notAllowedFileType: 'Недопустимый тип файла',
        filesCount: 'Некорректное количество файлов',
        noAccessRights: 'Недостаточно прав доступа',
        tokenExpired: 'Токен авторизации истек',
        failedToLoadApplication: 'Не удалось загрузить приложение, напишите нам: help@t-dev.pro',
        noAccessApp: 'У Вас нет доступа к системе Т-про, напишите нам: help@t-dev.pro',
        hasNoAccessRights: 'У вас недостаточно прав для доступа к данному функционалу, напишите нам: help@t-dev.pro',
        GenerateDocumentInfo: 'Не удалось создать документ',
        GenerateDocumentName: 'Не удалось создать документ',
        GenerateDocumentPath: 'Не удалось создать документ',
        NotAllowedUploadDocument: 'В данный каталог нельзя загружать документы',
        SaveDocumentVersion: 'Не удалось создать версию документа',
        CreateResourceFailed: 'При создание произошла неизвестная ошибка',
        ForbiddenResourceFailed: 'У вас недостаточно прав для проведения данной операции в этом каталоге',
        NotFound: 'Не удалось найти запрошенный ресурс',
        NotAllowedUploadDocuments: 'Сюда нельзя загружать документы',
        notUniqueOrganisationINN: 'Организация с данным ИНН уже была создана',
        notUniqueOrganisationPhone: 'Телефон уже был ранее использован в другой организации',
        notUniqueUserEmail: 'Данный адрес почты уже был использован ранее у другого пользователя',
        MissingPDFException: `Не удалось загрузить документ. Пожалуйста, проверьте, есть ли у вас права доступа, и не был ли удалён файл`,
        InvalidPDFException: 'PDF файл повреждён. Пожалуйста, проверьте его целостность',
        DocumentAgreed:
            'Для загрузки нового чертежа необходимо расторгнуть  документ в T.Cloud или изменить его статус в T.Pro.',
    },

    validationErrors: {
        'This action is unauthorized.': 'У вас недостаточно прав для выполнения данного действия',
        'The given data was invalid.': 'Введенные данные некорректны',
        'Организация с данным ИНН не найдена': 'Организация с данным ИНН не найдена',
        'Отправлять в работу может только представитель заказчика':
            'Отправлять в работу может только куратор со стороны заказчика',
        'Принять чертёж может только представитель заказчика':
            'Принять чертёж может только куратор со стороны заказчика',
        'Отправлять чертёж на проверку могут только представитель или исполнители проектной организации':
            'Отправлять чертёж на проверку могут только куратор или исполнители проектной организации',
        'Создавать задачи может только представитель заказчика':
            'Создавать задачи может только куратор со стороны заказчика',
        'validation.unique': 'Значение поля {field} не является уникальным',
        'Design not found': 'Проектирование не найдено',
    },

    messages: {
        requireField: 'Поле обязательно для заполнения',
    },

    unavailableOnMobileDevicePlug: {
        title: 'Данный раздел пока недоступен на мобильных устройствах',
        description: 'Используйте компьютер для доступа к полному функционалу программы',
    },

    uploader: {
        dragFilesHere: 'Перетащите файлы сюда',
        orSelectFiles: 'или выберите на компьютере',
        removeFile: 'Удалить файл',
        uploadMore: 'Загрузить еще',
        uploadFiles: 'Загрузить файлы',
        uploadMoreNFiles:
            'Загрузите еще {n} файлов | Загрузите еще {n} файл | Загрузите еще {n} файла | Загрузите еще {n} файлов',
        uploadImage: 'Загрузить изображение',
    },

    enums: {
        // OrganisationTypeEnum
        physicalPerson: 'Физическое лицо',
        legalPerson: 'Юридическое лицо',
        separateSubdivision: 'Обособленное подразделение',

        // OrganisationRoleEnum
        customer: 'Заказчик предписаний',
        designCustomer: 'Заказчик проектирования',
        generalContractor: 'Генеральный подрядчик',
        generalDesigner: 'Проектная организация',
        subcontractor: 'Субподрядчик',
    },

    naming: 'Название',
    actions: 'Действия',

    goBack: 'Вернуться назад',
    revoke: 'Отозвать',
    untie: 'Отвязать',
    cancel: 'Отменить',
    appoint: 'Назначить',
    create: 'Создать',
    update: 'Обновить',
    save: 'Сохранить',
    add: 'Добавить',
    delete: 'Удалить',
    edit: 'Редактировать',
    well: 'Хорошо',
    great: 'Отлично',
    complete: 'Завершить',
    confirm: 'Подтвердить',

    users: 'Пользователи',
    customer: 'Заказчик',
    customerPrescription: 'Заказчик предписаний',
    customerDesign: 'Заказчик проектирования',
    genContractor: 'Ген. подрядчик',
    genContractors: 'Ген. подрядчики',
    genDesigners: 'Ген. проектировщики',
    projectOrganization: 'Проектная организация',
    subContractor: 'Cуб. подрядчик',
    subContractors: 'Cуб. подрядчики',
    creationDate: 'Дата создания',
    address: 'Адрес',
    region: 'Регион',
    city: 'Город',
    cities: 'Город',
    citiesDashboard: 'Филиал',
    organisation: 'Организация',
    organisations: 'Организации',
    project: 'Проект',
    projects: 'Проекты',
    section: 'Секция',
    porch: 'Подъезд',
    sections: 'Секции',
    floor: 'Этаж',
    floors: 'Этажи',
    apartment: 'Квартира',
    apartments: 'Квартиры',

    name: 'Имя',
    surname: 'Фамилия',
    patronymic: 'Отчество',
    password: 'Пароль',
    passwordConfirm: 'Подтверждение пароля',
    role: 'Роль',
    roles: 'Роли',
    email: 'Электронная почта',
    position: 'Должность',
    inn: 'ИНН',
    kpp: 'КПП',
    ogrn: 'ОГРН',
    organisationType: 'Вид организации',
    fullName: 'Полное название',
    shortName: 'Краткое название',
    legalAddress: 'Юридической адрес',
    physicalAddress: 'Фактический адрес',
    contactNumber: 'Контактный номер {n}',
    projectPhoto: 'Изображение проекта',
    apartmentName: 'Название квартиры',
    director: 'Директор',

    dataSavedSuccessfully: 'Данные успешно сохранены',
    actualAddressIsDifferentFromLegal: 'Фактический адрес отличается от юридического',
    tryLaterIfError: 'Попробуйте позже. Если ошибка повторится, напишите в службу поддержки',

    current: 'Текущий',
    new: 'Новый',
    ver: 'Вер.',
    version: 'Версия',
    chat: 'Чат',
    copy: 'Копировать',
    send: 'Отправить',
    accept: 'Принять',
    completed: 'Завершено',
    perform: 'Выполнить',
    rejected: 'Отклонено',
    inWork: 'В работе',
    done: 'Выполнено',
    accepted: 'Принято',
    notAccepted: 'Не принято',
    created: 'Создано',
    agreed: 'Согласован',
    onChecking: 'На проверке',
    onRevision: 'На доработке',
    canceled: 'Отменен',
    draft: 'Черновик',
    clear: 'Понятно',
    error: 'Ошибка',
    remove: 'Удалить',
    attach: 'Прикрепить',
    filter: 'Фильтр',
    sort: 'Сортировка',
    saveSettings: 'Сохранить настройки',
    status: 'Статус',
    clearAll: 'Очистить все',
    documentType: 'Тип документа',
    download: 'Скачать',
    replacement: 'Замещения',
    beginDate: 'Дата начала',
    endDate: 'Дата окончания',
    comment: 'Комментарий',
    replaced: 'Замещаемый',
    substitute: 'Замещающий',
    serverError: 'Ошибка сервера',
    costFrom: 'Стоимость от',
    costTo: 'Стоимость до',
    select: 'Выбрать',
    or: 'или',
    print: 'Распечатать',
    object: 'Объект',
    cost: 'Цена',
    volume: 'Объём',
    number: 'Номер',
    executor: 'Исполнитель',
    type: 'Тип',
    realty: 'Недвижимость',
    realtyType: 'Тип недвижимости',
    to: 'До',
    remark: 'Замечание | Замечания',
    dateFrom: 'Дата от',
    dateTo: 'Дата до',
    show: 'Показать',
    more: 'больше',
    less: 'меньше',
    and: 'и',
    also: 'ещё',
    setting: 'Настройка<br/>уведомлений',
    notifications: 'Уведомления',
    from: 'от',
    numeroSign: '№',
    reject: 'Отклонить',
    reset: 'Сбросить',
    change: 'Изменить',
    applyClose: 'Применить и закрыть',
    files: 'Файлы',
    stopNow: 'Завершить',
    history: 'Журнал изменений',
    request: 'Запросить',
    good: 'Хорошо',
    subdevision: 'Подразделение',
    author: 'Автор',
    startedAt: 'Дата старта',
    createdAt: 'Дата создания',
    updatedAt: 'Дата обновления',
    title: 'Наименование',
    chart: 'График',
    documents: 'Документы',
    report: 'Отчеты',
    notAdded: 'Не добавлено',
    goOut: 'Выйти',
    viewing: 'Просмотр',
    compare: 'Сравнить',
    upload: 'Загрузить',
    continue: 'Продолжить',
    id: 'ID',
    note: 'Примечание',
    description: 'Описание',
    checker: 'Проверил',
    date: 'Дата',
    manager: 'Руководитель',
    designer: 'Куратор',
};
