import { camelCaseFileName } from '@/utils/Helper';

const messages: any = {};
const configFiles = require.context('./', true, /^(?!.*(index)).*\.ts$/);

configFiles.keys().forEach((fileName: string) => {
    const name = camelCaseFileName(fileName.split('/').pop()!.split('.')[0]);

    messages[name] = configFiles(fileName).default;
});

export default messages;
